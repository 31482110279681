<template>
	<div>
		<button @click="rejectUser">rejectUser</button>
		<button @click="reissue">reissue</button>
		<button @click="signInConfirm">signInConfirm</button>
		<button @click="changePassword">changePassword</button>
		<button @click="retryEmail">retryEmail</button>
		<button @click="deleteUser">deleteUser</button>
		<button @click="fetchCurrentUser">fetchCurrentUser</button>
		<button @click="fetchUser">fetchUser</button>
		<button @click="createUser">createUser</button>
		<button @click="updateUser">updateUser</button>
		<button @click="getMembership">멤버십정보(membership/using)</button>
		<button @click="getMemberships">맴버십정보들(portal->DOMS customerID의 속한 멤버십 모두)</button>
		<button @click="getRemainDays">멤버십 만료 정보</button>
		<button @click="newOrder">신규 주문(기존)</button>
		<button @click="goDashBoard">메인화면 이동</button>
		<button @click="kiwoom">신용카드 결제</button>
		<button @click="getLoginInfo">스토어에 저장된 로그인 정보</button>
		<button @click="logout">로그아웃</button>
	</div>
</template>

<script>
import { rejectUser, reissue, signInConfirm, changePassword, retryEmail, signOut } from '@/api/login';
import {
	createUser,
	deleteUser,
	fetchCurrentUser,
	fetchUser,
	updateUser,
	getMembership,
	getRemainDays,
	getMemberships,
} from '@/api/user';
import { payment } from '@/components/apply/mixinsPayment.js';

export default {
	mixins: [payment],
	name: 'Dev',
	methods: {
		rejectUser() {
			rejectUser();
		},
		reissue() {
			reissue();
		},
		signInConfirm() {
			signInConfirm();
		},
		async changePassword() {
			await changePassword({
				checkPassword: '1qaz2wsx#',
				newPassword: '1qaz2wsx#',
				oldPassword: '12qwaszx23!',
			});
			await changePassword({
				checkPassword: '12qwaszx23!',
				newPassword: '12qwaszx23!',
				oldPassword: '1qaz2wsx#',
			});
		},
		retryEmail() {
			retryEmail();
		},
		deleteUser() {
			deleteUser();
		},
		fetchCurrentUser() {
			fetchCurrentUser();
		},
		fetchUser() {
			fetchUser({ id: 1 });
		},
		createUser() {
			createUser();
		},
		updateUser() {
			updateUser();
		},
		getMembership() {
			getMembership();
		},
		getMemberships() {
			getMemberships();
		},
		getRemainDays() {
			const dateFormat = (date) => {
				return (
					date.getFullYear() +
					'-' +
					(date.getMonth() + 1 < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
					'-' +
					(date.getDay() < 9 ? '0' + (date.getDay() + 1) : date.getDay())
				);
			};
			const startDate = dateFormat(new Date());

			getRemainDays({
				membershipId: 12894,
				startDate,
			});
		},
		kiwoom() {
			const info = {
				payType: 'CREDITCARD',
				issuedBill: false,
				infoConsent: true,
				emailRecipientEmail: '',
				emailRecipientPhone: '',
				amount: 10,
				orderId: 113050,
				orderSummary: '공유형 외 1건',
				corporateName: '된장',
				serviceType: 'SAAS',
			};
			// localStorage.setItem('payMethodInfo', JSON.stringify(info));
			// await this.$router.push('/pay');
			this.goPayment(info);
		},
		async newOrder() {
			await this.$router.push('/apply/step1?servicetype=SAAS');
		},
		async goDashBoard() {
			await this.$router.push('/home');
		},
		getLoginInfo() {
			const currentUser = this.$store.state.login.currentUser;
			console.log(currentUser);
		},
		logout() {
			signOut();
		},
	},
};
</script>

<style scoped></style>
