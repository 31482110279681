export const payment = {
	// 결제 관련 methods
	methods: {
		async openPayRequestLayer(payMethodInfo) {
			// 결제 레이어 open
			await localStorage.setItem('payMethodInfo', JSON.stringify(payMethodInfo));
			await this.$store.commit('SET_PAY_METHOD_INFO', payMethodInfo);
			const popupUrl = this.getPopupUrl(payMethodInfo);
			window.open(
				popupUrl,
				'_blank',
				'width=884, height=582, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250',
			);
		},
		getPopupUrl(payMethodInfo) {
			// paytype에 따른 팝업창 분기처리
			return payMethodInfo.payType === 'CREDITCARD'
				? `${process.env.VUE_APP_FRONT_SERVER}/kiwoom/integration/popup`
				: `${process.env.VUE_APP_FRONT_SERVER}/kiwoom/popup`;
		},
		async close() {},
		async goNextPage(path) {
			// 다음 페이지로 이동
			if (this.$route.path !== path) this.$router.push(path);
		},
		async goPayment(payment, nextPath) {
			// 결제 창 open 후, 다음 페이지로 이동
			await this.openPayRequestLayer(payment);
			await window.addEventListener(
				'message',
				(event) => {
					if (event.data === 'success') {
						this.goNextPage(nextPath);
					}
				},
				false,
			);
		},
		checkAutoExtension(payMethodInfo) {
			if (!payMethodInfo.cardNo) {
				this.$ms.toast.show('카드번호를 입력해주세요');
				return false;
			}
			if (!payMethodInfo.expiredDt.year || !payMethodInfo.expiredDt.month) {
				this.$ms.toast.show('카드 유효기간(MM/YY)을 입력해주세요.');
				return false;
			}
			if (!payMethodInfo.cardPassword) {
				this.$ms.toast.show('카드비밀번호 앞 2자리를 입력해주세요.');
				return false;
			}
			if (!payMethodInfo.cardAuth) {
				this.$ms.toast.show('생년월일 혹은 사업자 번호를 입력해주세요.');
				return false;
			}
			return true;
		},
		async setAutoExtension(payMethodInfo, orderId, customerId, serviceType) {
			// 자동결제 api 호출
			const params = {
				payMethod: 'SSL',
				cpid: this.getAutoCpId(serviceType),
				autoCpid: this.getAutoCpId(serviceType),
				productType: 1,
				billType: 14,
				taxFreecd: '00',
				amount: payMethodInfo.amount, //결제금액
				productName: payMethodInfo.orderSummary,
				orderNo: this.getOrderNo(serviceType, orderId),
				userId: 'hsim',
				userName: payMethodInfo.corporateName, // 상호명
				autoMonths: 99,
				cardNo: payMethodInfo.cardNo,
				expiredt: '20'.concat(payMethodInfo.expiredDt.year, payMethodInfo.expiredDt.month),
				cardAuth: payMethodInfo.cardAuth,
				cardPassword: payMethodInfo.cardPassword,
				customerId: customerId,
				lastCardNo: payMethodInfo.cardNo.substr(12, 4),
			};

			const res = await this.$store.dispatch('setAutoExtension', params);
			if (res.errorCode !== '0000') {
				const errorMessage = res.errorCode + ':' + res.errorMessage;
				this.$ms.toast.show(errorMessage);
				return false;
			}

			return true;
		},
		getOrderNo(serviceType, orderId) {
			return 'DAOU_SAAS_' + orderId;
		},
		getAutoCpId(serviceType) {
			if (serviceType === 'VPC') {
				return window.__env.code.VPC_AUTO_CP_ID;
			} else {
				return window.__env.code.SAAS_AUTO_CP_ID;
			}
		},
	},
};
